<template>
  <div>
    <div class="qkxx_title">初审稿件</div>
    <div style="padding: 13px">
      <p class="part_title">上传用稿通知</p>
      <div>
        <el-form
          ref="form"
          label-width="100px"
          label-position="left"
          size="mini"
        >
          <el-form-item label="用稿通知">
            <el-upload
              class="upload-demo"
              ref="upload"
              :action="path"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :on-success="upsuccess"
              :on-change="beforeUpload"
              :data="{ id: wid }"
              :file-list="fileList"
              :auto-upload="false"
            >
              <div class="upload" slot="trigger">
                <i class="el-icon-link"></i>点击上传附件（用稿通知）
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="danger" size="samll" @click="submitUpload"
              >确定上传</el-button
            >
            <el-button size="samll" @click="later">后期补传</el-button>
            <span class="tip">说明：后期可以在已审核稿件中进行补传</span>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { laterupload } from "../../../API/QKD/check.js"
export default {
  data () {
    return {

      wid: "",
      fileList: [],
      form: [],
      fileName: "", // 获取文件文件名
      path: ""

    };
  },
  methods: {
    //这里是上传文件的方法
    submitUpload () {
      var that = this
      if (that.fileName == '') {
        that.$message.error('未选择文件')
      } else {
        that.$refs.upload.submit()
        setTimeout(function () {
          if (id == 4) {
            this.$router.push("/Periodical/firstcheck/?id=4")
          } else if (id == 5) {
            this.$router.push("/Periodical/secondcheck/?id=5")
          }
        }, 3000)
      }
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    handlePreview (file) {
      console.log(file);
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    upsuccess (res, file, filelist) {
      if (res.code == 0) {
        this.$message.success("上传成功")
        let id = this.$route.query.id
        if (id == 4) {
          this.$router.push("/Periodical/firstcheck/?id=4")
        } else if (id == 5) {
          this.$router.push("/Periodical/secondcheck/?id=5")
        }
      } else {
        this.$message.error("上传失败")
      }
    }, beforeUpload (file, fileList) {
      this.fileName = file.name
      console.log(file, fileList)
    },
    //以上是上传文件的方法
    later () {
      laterupload(this.$route.query.wid).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.$message.success("补录成功")
          let id = this.$route.query.id
          if (id == 4) {
            this.$router.push("/Periodical/firstcheck/?id=4")
          } else if (id == 5) {
            this.$router.push("/Periodical/secondcheck/?id=5")
          }
        }

      })
    }
  }, mounted () {
    this.wid = this.$route.query.wid
    this.path = this.URL + "/qikan/UploadNotice"
  }
}
</script>

<style>
.upload {
  width: 320px;
  height: 30px;
  line-height: 30px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #cccccc;
}
.tip {
  color: #999;
  font-size: 14px;
  margin-left: 10px;
}
</style>